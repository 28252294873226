/***

Sass Variables

Enter all variables used across stylesheets here.
These could include fonts, colors, measurements, etc.

***/

// COLORS
$true_black: #000000;
$black: #333333;
$gray: #C1C1C1;
$grey: $gray;
$white: #ffffff;
$gray_5:  lighten($true_black, 95%);
$gray_10: lighten($true_black, 90%);
$gray_15: lighten($true_black, 85%);
$gray_20: lighten($true_black, 80%);
$gray_25: lighten($true_black, 75%);
$gray_30: lighten($true_black, 70%);
$gray_35: lighten($true_black, 65%);
$gray_40: lighten($true_black, 60%);
$gray_45: lighten($true_black, 55%);
$gray_50: lighten($true_black, 50%);
$gray_55: lighten($true_black, 45%);
$gray_60: lighten($true_black, 40%);
$gray_65: lighten($true_black, 35%);
$gray_70: lighten($true_black, 30%);
$gray_75: lighten($true_black, 25%);
$gray_80: lighten($true_black, 20%);
$gray_85: lighten($true_black, 15%);
$gray_90: lighten($true_black, 10%);
$gray_95: lighten($true_black, 5%);
$green: #76881D;
$orange: #D57800;
$tan: #EBE49A;
$green-alt: #D6DBBB;
$orange-alt: #F2D6B2;
$tan-alt: #F9F7E1;

$column-border-color: #c1c1c1;

// TYPOGRAPHY
$frutiger-light: 'Frutiger LT W01_45 Ligh1475730', 'Helvetica Neue Light', 'Helvetica Light', 'Arial', sans-serif;
$frutiger-light-italic: 'Frutiger LT W01_46 Ligh1475734', 'Helvetica Neue Light', 'Helvetica Light', 'Arial', sans-serif;
$frutiger-italic: 'Frutiger LT W01_56 Italic', 'Helvetica Neue Light', 'Helvetica Light', 'Arial', sans-serif;
$frutiger: 'Frutiger LT W01_55 Roma1475738', 'Helvetica Neue Light', 'Helvetica Light', 'Arial', sans-serif;
$frutiger-bold-italic: 'Frutiger LT W01_66 Bold Italic', 'Helvetica Neue Light', 'Helvetica Light', 'Arial', sans-serif;
$frutiger-bold: 'Frutiger LT W01_65 Bold1475746', 'Helvetica Neue Light', 'Helvetica Light', 'Arial', sans-serif;
$gianotten: 'Gianotten LT W01 Light', 'Times New Roman', serif;
$gianotten-italic: 'Gianotten LT W01 Italic', 'Times New Roman', serif;

// Spacing
$large-space: 96px;
$medium-space: 48px;
$small-space: 24px;

// Breakpoints
$desktop: 960px;
$large-desktop: 1452px;

// MISC
$transition-default: all 0.3s ease;
