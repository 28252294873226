/***

Editor

***/

/* mce format icon button */

.mce-i-button {
    display: none !important;

    & ~ .mce-text {
        display: inline-block;
        margin: 0 0 -2px;
        padding: 0.6em 1.4em 0.5em;
        font-size: 12px !important;
        color: $white !important;

        .mce-disabled & {
            opacity: .3;
        }
    }

    &.button--extra-small ~ .mce-text {
        background-color: $orange;
    }

    &.button--small ~ .mce-text {
        background-color: $orange;
    }

    &.button--outline--small ~ .mce-text {
        border: 1px solid $orange;
        color: $orange !important;
    }

    &.button--medium.button--orange ~ .mce-text {
        background-color: $orange;
    }
    &.button--medium.button--green ~ .mce-text {
        background-color: $green;
    }
    &.button--medium.button--white ~ .mce-text {
        background-color: $gray;
    }

    &.button--outline--medium ~ .mce-text {
        border: 1px solid $orange;
        color: $orange !important;
    }

    &.button--large.button--orange ~ .mce-text {
        background-color: $orange;
    }
    &.button--large.button--green ~ .mce-text {
        background-color: $green;
    }
    &.button--large.button--white ~ .mce-text {
        background-color: $gray;
    }

    &.button--outline--large ~ .mce-text {
        border: 1px solid $orange;
        color: $orange !important;
    }

    &.bar-button ~ .mce-text {
        display: inline-block;
        border-top: 1px solid $orange;
    }
}
