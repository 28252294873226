/***

Login

***/

body.login {
    background: #f9f9f9;
    h1 > a {
        background-color: transparent;
        background-image: url('../images/logo-alt.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: auto;
        display: block;
        height: 125px;
        overflow: hidden;
        text-indent: -9999px;
        width: 312px;
    }
    form {
        .input {}
    }
    label {}
    #nav,
    #backtoblog {
        > a {}
    }
}
